import { TodoServiceClient } from '@/protobuf/todo_grpc_web_pb.js'
import { TodoIdCollection, Todo, TodoCollection, newTodo, NewTodoCollection, Status, TableName, Empty } from '@/protobuf/todo_pb'

export default class TodoService {
  constructor(uri) {
    this.service = new TodoServiceClient(uri, null, null)
  }

  // DEVNOTE: all calls are engineered to the same multi-faceted pattern for dev-friendly convenience and conformity
  // DEVNOTE: You know when something takes shape correctly when you start to get a lot of symmetry :) - getTodo and deleteTodo are now identical methods albeit just the grpc stub differs - arguably could be consolidated but just cause you can doesn't always mean you should

  hasTable = (name) => {
    const request = new TableName()
    request.setName(name)

    return new Promise((resolve, reject) => {
      this.service.hasTable(request, null, (error, response) => {
        if (error) {
          console.log(
            `Unexpected error from TodoServiceClient.hasTable() gRPC request`
          )
          reject(error)
        }

        if (response && response.toObject) resolve(response.toObject().status)
        else resolve([]) // DEVNOTE: workaround for null/empty responses that won't have toObject() property
      })
    })
  }

  getTableNames = () => {
    return new Promise((resolve, reject) => {
      this.service.getTableNames(new Empty(), null, (error, response) => {
        if (error) {
          console.log(
            `Unexpected error from TodoServiceClient.getTableNames() gRPC request`
          )
          reject(error)
        }

        if (response && response.toObject) resolve(response.toObject().tablesList)
        else resolve([]) // DEVNOTE: workaround for null/empty responses that won't have toObject() property
      })
    })
  }

  getTable = (name) => {
    const request = new TableName()
    request.setName(name)

    return new Promise((resolve, reject) => {
      this.service.getTable(request, null, (error, response) => {
        if (error) {
          console.log(
            `Unexpected error from TodoServiceClient.getTable() gRPC request`
          )
          reject(error)
        }

        if (response && response.toObject) resolve(response.toObject().todosList)
        else resolve([]) // DEVNOTE: workaround for null/empty responses that won't have toObject() property
      })
    })
  }

  createTable = (name) => {
    const request = new TableName()
    request.setName(name)

    return new Promise((resolve, reject) => {
      this.service.createTable(request, null, (error, response) => {
        if (error) {
          console.log(
            `Unexpected error from TodoServiceClient.createTable() gRPC request`
          )
          reject(error)
        }

        if (response && response.toObject) resolve(response.toObject().name)
        else resolve([]) // DEVNOTE: workaround for null/empty responses that won't have toObject() property
      })
    })
  }

  cloneTable = (name) => {
    const request = new TableName()
    request.setName(name)

    return new Promise((resolve, reject) => {
      this.service.cloneTable(request, null, (error, response) => {
        if (error) {
          console.log(
            `Unexpected error from TodoServiceClient.cloneTable() gRPC request`
          )
          reject(error)
        }

        if (response && response.toObject) resolve(response.toObject().name)
        else resolve([]) // DEVNOTE: workaround for null/empty responses that won't have toObject() property
      })
    })
  }

  deleteTable = (name) => {
    const request = new TableName()
    request.setName(name)

    return new Promise((resolve, reject) => {
      this.service.deleteTable(request, null, (error, response) => {
        if (error) {
          console.log(
            `Unexpected error from TodoServiceClient.deleteTable() gRPC request`
          )
          reject(error)
        }

        if (response && response.toObject) resolve(response.toObject().todosList)
        else resolve([]) // DEVNOTE: workaround for null/empty responses that won't have toObject() property
      })
    })
  }

  /**
  * Read
  * 
  * @param {string} tableName
  * @param {!string | !number | !(string | number)[]} idCollection
  * @return {TodoCollection}
  */
  getTodo = (tableName, idCollection = []) => {
    // console.log(
    //   `TodoServiceClient.getTodo() =>
    //       tableName: ${tableName}
    //       id:`, idCollection
    // )

    idCollection = Array.isArray(idCollection) ? idCollection : [parseInt(idCollection)]

    const request = new TodoIdCollection()
    request.setIdList(idCollection)
    request.setName(tableName)
    // console.log('TodoServiceClient.getTodo() :getIdList: ', request.getIdList())

    return new Promise((resolve, reject) => {
      this.service.getTodo(request, null, (error, response) => {
        if (error) {
          console.log(
            `Unexpected error from TodoServiceClient.getTodo() gRPC request:
                code = ${error.code}
                message = "${error.message}"`
          )
          reject(error)
        }
        // console.log('get RESPONSE :: ', response.getTodosList())

        if (response && response.toObject) resolve(response.toObject().todosList)
        else resolve([]) // DEVNOTE: workaround for null/empty responses that won't have toObject() property
      })
    })
  }

  /**
  * Delete
  * 
  * @param {string} tableName
  * @param {!string | !number | !(string | number)[]} idCollection
  * @return {TodoCollection}
  */
  deleteTodo = (tableName, idCollection = []) => {
    // console.log(
    //   `TodoServiceClient.deleteTodo() =>
    //       tableName: ${tableName}
    //       id:`, idCollection
    // )

    idCollection = Array.isArray(idCollection) ? idCollection : [parseInt(idCollection)]

    const request = new TodoIdCollection()
    request.setName(tableName)
    request.setIdList(idCollection)

    return new Promise((resolve, reject) => {
      this.service.deleteTodo(request, null, (error, response) => {
        if (error) {
          console.log(
            `Unexpected error from TodoServiceClient.createTodo() gRPC request:
                code = ${error.code}
                message = "${error.message}"`
          )
          reject(error)
        }
        resolve(response.toObject().todosList)
      })
    })
  }

  /**
  * Create
  * 
  * @param {string} tableName
  * @param {Todo} payload
  * @return {TodoCollection}
  */
  createTodo = (tableName, payload) => {
    // console.log(
    //   `TodoServiceClient.createTodo() =>
    //       tableName: ${tableName}
    //       payload: `, payload
    // )

    payload = Array.isArray(payload) ? payload : [payload]

    const collection = payload.map(({ task, position, status }) => {
      const todo = new newTodo()
      todo.setTask(task)
      todo.setPosition(position)
      todo.setStatus(status)

      return todo
    })

    const request = new NewTodoCollection()
    request.setName(tableName)
    request.setTodoList(collection)

    return new Promise((resolve, reject) => {
      this.service.createTodo(request, {}, (error, response) => {
        if (error) {
          console.log(
            `Unexpected error from TodoServiceClient.createTodo() gRPC request:
                code = ${error.code}
                message = "${error.message}"`
          )
          reject(error)
        }

        if (response && response.toObject) resolve(response.toObject().todosList)
        else resolve([]) // DEVNOTE: workaround for null/empty responses that won't have toObject() property
      })
    })
  }

  /**
  * Update
  * 
  * @param {string} tableName
  * @param {Todo} payload
  * @return {TodoCollection}
  */
  /* updateSingleTodo = ({ id, task, position }) => {
    console.log(
      `updateSingleTodo() >>
        id: ${id}
        task: ${task}
        position: ${position}`
    )

    const request = new Todo()
    request.setId(parseInt(id))
    request.setTask(task)
    request.setPosition(position)

    return new Promise((resolve, reject) => {
      this.service.updateSingleTodo(request, {}, (error, response) => {
        if (error) reject(error)

        resolve(response.toObject().todosList)
      })
    })
  } */

  updateTodo = (tableName, payload) => {
    console.log(
      `TodoServiceClient.updateTodo() =>
          tableName: ${tableName}
          payload: `, payload
    )

    payload = Array.isArray(payload) ? payload : [payload]

    const collection = payload.map(({ id, task, position, status }) => {
      const todo = new Todo()
      todo.setId(parseInt(id))
      todo.setPosition(position)
      todo.setTask(task)
      todo.setStatus(status)

      return todo
    })

    const request = new TodoCollection()
    request.setName(tableName)
    request.setTodoList(collection)

    return new Promise((resolve, reject) => {
      this.service.updateTodo(request, {}, (error, response) => {
        if (error) {
          console.log(
            `Unexpected error from TodoServiceClient.createTodo() gRPC request:
                code = ${error.code}
                message = "${error.message}"`
          )
          reject(error)
        }

        resolve(response.toObject().todosList)
      })
    })
  }
}
