<template lang="pug">
  #app(v-cloak)
    header.app__masthead
      p
        small pix8 Ltd &copy; 2022
        span.seperator &#32;-&#32;
        b Technology demonstrator

    ul.stack__listing.stack__listing--top
      li.stack__list-item
        img(src="@/img/logo__docker.svg" alt="Docker")
      li.stack__list-item
        img(src="@/img/logo__nginx.svg" alt="NGINX")
      li.stack__list-item
        img(src="@/img/logo__envoy.svg" alt="Envoy")
      li.stack__list-item
        img(src="@/img/logo__nodejs.svg" alt="Node.js")
      li.stack__list-item
        img(src="@/img/logo__grpc.svg" alt="gRPC")
    ul.stack__listing.stack__listing--right
      li.stack__list-item
        img(src="@/img/logo__vuejs.svg" alt="Vue.js")
      li.stack__list-item
        img(src="@/img/logo__javascript.svg" alt="JavaScript")
      li.stack__list-item
        img(src="@/img/logo__pug.svg" alt="Pug")
      li.stack__list-item
        img(src="@/img/logo__sass.svg" alt="SASS")
      li.stack__list-item
        img(src="@/img/logo__sqlite.svg" alt="SQLite")
      li.stack__list-item
        img(src="@/img/logo__knexjs.svg" alt="Knex.js")
      li.stack__list-item
        img(src="@/img/logo__circleci.svg" alt="Docker")
    
    nav.app__navigation
      ul.list__unstyled
        li: router-link(to="/") Meta Todo List using gRPC
        li: router-link(to="/hello-world") Hello World Example
        li: router-link(to="/route-guide") Route Guide Example
        //- li: router-link(to="/echo-app") Echo App Example
        li: router-link(to="/about") About
        //- li: router-link(to="/product-inventory") Product Inventory Example

    main.app__body
      router-view
</template>

<script>
export default {
  name: 'App',
}
</script>

<style lang="scss">
@mixin for-mobile-only {
  @media (max-width: 599px) { @content; }
}
@mixin for-portrait-up {
  @media (min-width: 600px) { @content; }
}
@mixin for-landscape-up {
  @media (min-width: 900px) { @content; }
}
@mixin for-desktop-up {
  @media (min-width: 1200px) { @content; }
}
@mixin for-widescreen-up {
  @media (min-width: 1800px) { @content; }
}


[v-cloak] {
  display: none;

  &:before { content: "Loading..." }
}

#app {
  display: grid;
  grid-template-columns:
    [full-start]  40px
    [main-start]  1fr [main-end]
                  40px [full-end];
  grid-template-rows: auto auto 1fr auto auto;
  grid-template-areas:
    '. masthead .'
    '. nav .'
    '. body .'
    '. top .'
    '. right .';
    min-height: 100vh;
  
  @include for-portrait-up {
    grid-template-columns:
      [full-start]  1fr
      [main-start]  600px [main-end]
                    1fr [full-end];
    grid-template-rows: auto auto auto 1fr 1fr;
    grid-template-areas:
    'masthead masthead .'
    '. top right'
    '. nav right'
    '. body right'
    '. body .';
  }

  @include for-desktop-up {
    grid-template-columns:
      [full-start]  1fr
      [main-start]  min(50%, calc(1280px / 2))
                    min(50%, calc(1280px / 2))  [main-end]
                    1fr [full-end];
    grid-template-areas:
      '. masthead top right'
      '. nav nav right'
      '. body body right'
      '. body body .';
    margin-top: 30px;
  }
}

.app__masthead {
  grid-area: masthead;

  @include for-portrait-up {
    padding-left: (30px + 120px);
  }

  .seperator {
    display: none;

    @include for-portrait-up {
      display: inline;
    }
  }

  b {
    display: block;

    @include for-portrait-up {
      display: inline;
    }
  }
}

.app__navigation {
  grid-area: nav;
  display: block;

  ul {
    display: flex;
    gap: 15px;
    margin: 30px 0;
  }
}

.app__body {
  grid-area: body;
  align-self: center;

  @include for-portrait-up {
    margin: 0 30px 0 30px;
  }

  @include for-desktop-up {
    margin: 0 120px 0 0;
  }
}

.stack__listing + .stack__listing {
  margin-bottom: 2em;

  @include for-portrait-up {
    margin-bottom: 0;
  }
}

.stack__listing--top {
  grid-area: top;
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  justify-content: space-between;

  @include for-portrait-up {
    flex-wrap: nowrap;
    overflow: hidden;
    justify-content: flex-end;
  }

  .stack__list-item {
    @include for-portrait-up {
      margin: 1em 2em;
    }
  }

  li:last-child {
    @include for-portrait-up {
      margin-right: 3em;
    }
  }
}

.stack__listing--right {
  grid-area: right;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;

  @include for-portrait-up {
    flex-direction: column;
    overflow: hidden;
  }

  .stack__list-item {
    @include for-portrait-up {
      margin: 2em 1em;
    }
  }

  li:first-child {
    @include for-portrait-up {
      margin-top: 1em;
    }
  }
}

.stack__list-item {
  display: inline-block;
  height: 16px;
  margin: 1em 0;

  @include for-portrait-up {
    height: 22px;
    margin: 1em 20px;
  }
}

.stack__list-item img {
  display: block;
  width: auto;
  height: 100%;
}
</style>
