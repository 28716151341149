<template lang="pug">
  .view__todo-list
    //- p: button.control.control__button.control__button--primary(type="button" @click="shuffle") Shuffle the deck

    //- p <b>Endpoint</b>: todos {{ todos }}

    //-
      p(style="display: flex; align-items: center; gap: 30px;")
        span Has table(prop)? = <strong>{{ hasTable }}</strong>
        span Active table(computed) = <strong>{{ activeTable }}</strong>
        span Route param id = <strong>{{ $route.params.id }}</strong>
      
      p(style="display: flex; align-items: center; gap: 30px;")
        span Ownership = <strong>{{ isOwner }}</strong>
        span Token = <strong>{{ token }}</strong>

    //-
      p.form__field.form__field--inline(v-if="filteredTableCollection.length")
        label(style="margin-right: 10px;") Select your todo list
        select(v-model="activeTable" @change="tableChangeHandler")
          option(:value="null") None
          option(v-for="name in filteredTableCollection" :key="name") {{ name }}

    //- p: pre: code All table names {{ tableCollection }}
    //- p: pre: code All todo list table names {{ filteredTableCollection }}

    //-
      p
        button.control.control__button.control__button--primary(type="button" @click="deleteTodoListkHandler" v-if="hasTable")
          | Delete list
          Icon(icon="trash-alt" title="Remove List" style="margin-left:10px;")
        button.control.control__button.control__button--primary(type="button" @click="saveTodoListHandler" v-else)
          | Save list
          Icon(icon="save" title="Save List" style="margin-left:10px;")

    .ui__todo-list
      //-
        aside.ui__dev
          h3 cachedTodos
          pre: code {{ JSON.stringify(cachedTodos, null, 2) }}

          h3 filteredTodos
          pre: code {{ JSON.stringify(filteredTodos, null, 2) }}
          
        aside.ui__dev2
          h3 editTodo [singular]
          pre: code {{ JSON.stringify(editTodo, null, 2) }}

          h3 editTodoCollection [plural]
          pre: code {{ JSON.stringify(editTodoCollection, null, 2) }}

          h3 filteredEditTodoCollection
          pre: code {{ JSON.stringify(filteredEditTodoCollection, null, 2) }}

      .todo-list__capture.form__field
        .form__control.form__control--group
          input(
            type="text"
            placeholder="What's your chosen procrastination?"
            autocomplete="off"
            autofocus
            v-model="newTodo"
            :disabled="isSingleEdit || isMultiEdit"
            @keyup.enter="addTodoHandler"
          )
          button.control.control__button.control__button--primary(type="button" @click="addTodoHandler" :disabled="!this.newTodo || isSingleEdit || isMultiEdit")
            span.label__task Add task
            Icon(icon="plus")
            
      .todo-list__toolbar
        template(v-if="isMultiEdit")
          p
            button(type="button" @click="cancelSelectedHandler")
              Icon(icon="times-circle" title="Cancel")
            span: b {{ filteredEditTodoCollection.length }} {{ 'item' | pluralize(filteredEditTodoCollection.length) }} selected:
          ul
            li
              button(type="button" @click="deleteSelectedHandler")
                | Delete selected
                Icon(icon="trash-alt" title="Remove selected")
            li
              button(type="button" @click="saveSelectedHandler" :disabled="!isModified")
                | Save changes
                Icon(icon="save" title="Save selected")
        template(v-else)
          ul
            li(v-if="filteredTodos.length")
              button(type="button" @click="selectAllHandler" :disabled="isSingleEdit")
                | Select all
      
      //- .todo-list__body(v-show="!isLoading")
      .todo-list__body
        transition-group(
          :class="['todo-list__listing', 'list__unstyled', {'todo-list__listing--single-edit': isSingleEdit, 'todo-list__listing--multi-edit': isMultiEdit}]"
          name="todo-list"
          tag="ul"
        )
          //- li(:class="['todo-list__list-item', { 'todo-list__list-item--over': item.isDragOver, 'todo-list__list-item--dragging': item === beingDraggedTodo }]"
          li(
            :class="['todo-list__list-item', { 'todo-list__list-item--over': item === lastEnteredTodo, 'todo-list__list-item--dragging': item === beingDraggedTodo }]"
            v-for="item in filteredTodos"
            :key="item.id"
            draggable
            @dragstart="dragstartTodoHandler($event, item)"
            @dragend="dragendTodoHandler($event, item, todos)"
            @dragenter.prevent="dragenterTodoHandler($event, item)"
            @dragleave="dragleaveTodoHandler($event, item)"
            @dragover.prevent
            @drop.prevent="dropTodoHandler($event, item)"
          )
            article(:class="['ui__todo', {'ui__todo--single-edit': editTodo.includes(item), 'ui__todo--complete': item.status === STATUS.COMPLETE}]")
              label.control.control__checkbox
                input(type="checkbox" :checked="item.status === STATUS.COMPLETE" @change="changeTodoHandler($event, item)")
                .control__checkbox-handle
                  Icon(icon="check")
              
              .todo__task(:position="item.position")
                p.task__body(
                  @dblclick="todoTextDblClickHandler(item)"
                  v-if="!(editTodo.includes(item) || editTodoCollection.includes(item))"
                )
                  | {{ item.task }}
                p.task__field(v-else)
                  input(
                    type="text"
                    v-focus
                    v-model="item.task"
                    @keyup.enter="saveTodoHandler(item)"
                    @keyup.esc="cancelTodoHandler(item)"
                    @blur="todoTextBlurHandler($event, item)"
                  )
                //- p test :: {{ Object.keys(STATUS).find(key => STATUS[key] === item.status) }}
                //- p test :: {{ Object.keys(STATUS)[item.status] }}

              .todo__toolbar(v-if="!editTodoCollection.length")
                button.control(type="button" @click="removeTodoHandler(item.id)" v-if="!isSingleEdit")
                  Icon(icon="trash-alt" title="Remove")
                button.control(type="button" @click="cancelTodoHandler(item)" v-else-if="editTodo.includes(item)")
                  Icon(icon="times-circle" title="Cancel")
            
              .todo__actions(:class="{'todo__actions--select': editTodoCollection.includes(item)}" v-if="!isSingleEdit")
                input.control.control__checkbox(type="checkbox" v-model="editTodoCollection" :value="item")
          
          li(v-if="!filteredTodos.length" key="empty") Nowt

      .todo-list__auxiliary(:class="{'todo-list__auxiliary--single-edit': isSingleEdit}")
        p.count {{ incompleteCount }} {{ 'task' | pluralize(incompleteCount) }} remaining
        p(v-show="incompleteCount < todos.length && isFilter !== 2")
          button(href="" @click.prevent="clearCompletedTodosHandler" :disabled="isSingleEdit")
            | Clear completed
            Icon(icon="eraser" style="margin-left: 9px;")
      
      .todo-list__actions.form__field
        .form__control.form__control--group
          span(style="margin-right: 16px;") Show
          label.control.control__radio.control__radio--button
            input(type="radio" :value="0" :disabled="isSingleEdit" v-model="isFilter")
            span all
          label.control.control__radio.control__radio--button
            input(type="radio" :value="1" :disabled="isSingleEdit || !completeCount || completeCount == todos.length" v-model="isFilter")
            span complete
          label.control.control__radio.control__radio--button
            input(type="radio" :value="2" :disabled="isSingleEdit || !incompleteCount || incompleteCount == todos.length" v-model="isFilter")
            span incomplete
</template>

<script>
import _ from 'lodash'
import TodoService from '@/js/TodoService'
import { Status } from '@/protobuf/todo_pb'

const PROTOCOL = 'http'
const PORT = 8080
const API_URI = `${PROTOCOL}://${window.location.hostname}:${PORT}`

const api = new TodoService(API_URI)

export default {
  name: 'TodoList',

  filters: {
    pluralize: (value, length, plural = value + 's') => {
      if (!value) return '';

      return length === 1 ? value : plural;
    },
  },

  props: {
    hasTable: {
      type: Boolean,
      required: true,
      default: false
    }
  },

  data() {
    this.cachedTodos = []
    this.STATUS = Status
    
    return {
      todos: [],
      editTodo: [],
      editTodoCollection: [],
      newTodo: '',
      isFilter: 0,
      isLoading: true,
      isSaving: false,
      // beingDraggedElement: null,
      beingDraggedTodo: null,
      /* lastEnteredElement: null, */
      lastEnteredTodo: null,

      token: '',
      tableName: null,
      tableCollection: [],
      fooTable: false,
    }
  },

  async created() {
    this.token = localStorage.getItem(this.$route.params.id)

    try {
      // DEV
      this.tableCollection = await api.getTableNames()

      if(this.$route.params.id) {
        console.log('== Yay table found ==')
        this.todos = await api.getTodo(this.$route.params.id)
      }

      // this.isLoading = false
    } catch (error) {
      console.log(`Unexpected error upon initialisation attempting to connect to the service: code = ${error.code}` + `, message = "${error.message}"`)
    }
  },

  methods: {
    shuffle() {
      // TODO: need to save change to database
      this.todos = _.shuffle(this.todos).map((item, index) => {
        return {...item, position: index+1}
      })
    },

    // JB: note could do the ordering at database level with sql/orm/knex
    sortByAscending: (a, b) => a.position - b.position,

    async addTodoHandler() {
      console.log('\naddTodoHandler() >> ', this.newTodo)

      if(!this.newTodo) return false

      const payload = this.newTodo.split(',').map((item, index) => {
        if(item.trim().length) {
          return {
            task: item.trim(),
            status: 0,
            position: this.todos.length + (index+1) //++this.todos.length // JB: causing stray undefined entry to appear in this.todos/unintentional causing sql to break
          }
        }
      }).filter(Boolean)

      if(!payload.length) return false

      if(!this.isOwner) {
        console.log('-- Not your sandbox sunshine! :: addTodoHandler() --')

        try {
          const tableName = await api.cloneTable(this.activeTable)

          localStorage.setItem(tableName, tableName)

          await this.$router.push({ name: 'home', params: { id: tableName }})
        } catch(error) {
          console.log(`Unexpected error for addTodoHandler = >api.cloneTable: code = ${error.code}` + `, message = "${error.message}"`)
        }
      }

      else if(!this.hasTable) {
        console.log('-- No table we need to create one! --')

        try {
          const tableName = await api.createTable()

          localStorage.setItem(tableName, tableName)
        
          await this.$router.push({ name: 'home', params: { id: tableName }})
        } catch(error) {
          console.log(`Unexpected error from addTodoHandler => api.createTable: code = ${error.code}` + `, message = "${error.message}"`)
        }
      }

      try {
        console.log('==== logic async END ===')
        this.todos = await api.createTodo(this.activeTable, payload)

        console.log('==== logic async END ===')
      } catch (error) {
        console.log(`Unexpected error from addTodoHandler => api.createTodo: code = ${error.code}` + `, message = "${error.message}"`)
      }

      this.newTodo = ''
    },

    async changeTodoHandler(event, item) {
      // console.log('\changeTodoHandler() >> ', item)

      item.status = (event.target.checked) ? Status.COMPLETE : Status.INCOMPLETE

      if(!this.isOwner) {
        console.log('Not your sandbox sunshine! changeTodoHandler()')

        try {
          // const tableName = await api.createTable()
          // await api.createTodo(tableName, [...this.todos])
          // await api.updateTodo(tableName, [item])

          const tableName = await api.cloneTable(this.activeTable)

          localStorage.setItem(tableName, tableName)
        
          await this.$router.push({ name: 'home', params: { id: tableName }})
        } catch(error) {
          console.log(`Unexpected error for changeTodoHandler: code = ${error.code}` + `, message = "${error.message}"`)
        }
      }

      // else {
        try {
          const response = await api.updateTodo(this.activeTable, [item])

          // JB: Selectively update this.todos/UI. Is this necessary?
          response.forEach((item, index) => {
            // console.log(item, ' :: ', this.todos[index], ' same? ', (item == this.todos[index]), ' :: ', (JSON.stringify(item) === JSON.stringify(this.todos[index])) )
            
            Object.assign({}, this.todos[index], {status: item.status})
            // this.todos[index] = {...this.todos[index], ...{status: item.status}}
          })
        } catch(error) {
          console.log(`Unexpected error for changeTodoHandler: code = ${error.code}` + `, message = "${error.message}"`)
        }
      // }
    },

    async clearCompletedTodosHandler() {
      // console.log('\nclearCompletedTodosHandler() >> ')

      const confirmation = confirm('Are you sure? This operation can not be undone');

      if(!confirmation) return false

      const completedCollection = this.todos.reduce((accum, curr) => {
        if(curr.status === Status.COMPLETE) accum.push(curr.id)
        return accum;
      }, [])

      if(!this.isOwner) {
        console.log('Not your sandbox sunshine!')

        try {
          // const tableName = await api.createTable()
          // this.todos = await api.createTodo(tableName, [...this.todos])

          const tableName = await api.cloneTable(this.activeTable)

          // completedCollection.forEach(async (id) => {
          //   this.todos = await api.deleteTodo(tableName, id)
          // })

          localStorage.setItem(tableName, tableName)
        
          await this.$router.push({ name: 'home', params: { id: tableName }})
        } catch(error) {
          console.log(`Unexpected error for clearCompletedTodosHandler: code = ${error.code}` + `, message = "${error.message}"`)
        }
      }

      // else {
        try {
          completedCollection.forEach(async (id) => {
            this.todos = await api.deleteTodo(this.activeTable, id)
          })
        } catch(error) {
          console.log(`Unexpected error for clearCompletedTodosHandler: code= ${error.code}` + `, message = "${error.message}"`)
        }
      // }
    },

    /*
    * Single-select edit mode
    **/
   todoTextDblClickHandler(item) {
      // console.log('\ntodoTextDblClickHandler() >> ', item)

      if(this.isMultiEdit) return false // DEVNOTE: if multi edit/select is active

      const index = this.editTodo.indexOf(item)
      // if(index < 0) this.editTodo.push(item)
      // DEVNOTE: workaround for this craveat when watching non-primitives, new object/array will override prior reference; https://v2.vuejs.org/v2/api/?redirect=true#vm-watch
      if(index < 0) this.editTodo = [...this.editTodo, item]
    },

    async todoTextBlurHandler(event, item) {
      console.log('\ntodoTextBlurHandler() >> ', event.target, event.relatedTarget, item, ' >> ', document.activeElement)

      // Process to save only if something has changed
      // If we are in multi-select edit mode then we don't use the save upon entry feature
      // If there exists a relatedTarget then the cause of the blur was a switch of elements // blur = eventTarget the node losing focus; relatedTarget the node receiving focus(if any)
      if(event.relatedTarget) return false
      if(this.isMultiEdit) return false
      if(!this.isModified) return false

      this.isSaving = true

      const index = this.editTodo.indexOf(item);
      this.editTodo.splice(index, 1)
      // DEVNOTE: workaround for this craveat when watching non-primitives; https://v2.vuejs.org/v2/api/?redirect=true#vm-watch
      // this.editTodo = this.editTodo.filter((todo) => todo !== item)

      if(!this.isOwner) {
        console.log('Not your sandbox sunshine! todoTextBlurHandler()')

        try {
          // const tableName = await api.createTable()
          // this.todos = await api.createTodo(tableName, [...this.todos])
          // await api.updateTodo(tableName, [item])

          const tableName = await api.cloneTable(this.activeTable)

          this.isSaving = false

          localStorage.setItem(tableName, tableName)
        
          await this.$router.push({ name: 'home', params: { id: tableName }})
        } catch(error) {
          console.log(`Unexpected error for todoTextBlurHandler: code = ${error.code}` + `, message = "${error.message}"`)
        }
      }

      // else {
        try {
          // this.todos = await api.updateTodo(this.activeTable, [item]) // DEVNOTE: api endpoint not set up to work off singulur only plural[]
          const response = await api.updateTodo(this.activeTable, [item])

          // JB: Selectively update this.todos/UI. Is this necessary?
          response.forEach((item, index) => {
            // console.log(item, ' :: ', this.todos[index], ' same? ', (item == this.todos[index]), ' :: ', (JSON.stringify(item) === JSON.stringify(this.todos[index])) )
            // Object.assign({}, this.todos[index], item)
            Object.assign({}, this.todos[index], {status: item.status === Status.COMPLETE})
            // this.todos[index] = {...this.todos[index], ...{status: item.status}}
          })

          this.isSaving = false
        } catch(error) {
          console.log(`Unexpected error for todoTextBlurHandler: code = ${error.code}` + `, message = "${error.message}"`)
        }
      // }
    },

    async saveTodoHandler(item) {
      // console.log('\nsaveTodoHandler() >> ', this.isModified)

      if(this.editTodoCollection.length) return false // DEVNOTE: if multi edit/select is active
      if(!this.isModified) return false
      
      this.isSaving = true

      if(!this.isOwner) {
        console.log('Not your sandbox sunshine! saveTodoHandler()')

        try {
          // const tableName = await api.createTable()
          // await api.createTodo(tableName, [...this.todos])
          // await api.updateTodo(tableName, [item])

          const tableName = await api.cloneTable(this.activeTable)

          this.isSaving = false

          localStorage.setItem(tableName, tableName)
        
          await this.$router.push({ name: 'home', params: { id: tableName }})
        } catch(error) {
          console.log(`Unexpected error for saveTodoHandler: code = ${error.code}` + `, message = "${error.message}"`)
        }
      }

      // else {
        try {
          this.todos = await api.updateTodo(this.activeTable, [item]) // JB: doesn't seem to break the this.todos vs this.editTodoCollection reference comparison 

          this.isSaving = false
        } catch(error) {
          console.log(`Unexpected error for saveTodoHandler: code = ${error.code}` + `, message = "${error.message}"`)
        }
      // }
    },

    async removeTodoHandler(id) {
      // console.log('\nremoveTodoHandler() >> ', id)

      if(!this.isOwner) {
        console.log('Not your sandbox sunshine! removeTodoHandler()')

        try {
          // const tableName = await api.createTable()
          // await api.createTodo(tableName, [...this.todos])
          // await api.deleteTodo(tableName, id)

          const tableName = await api.cloneTable(this.activeTable)

          localStorage.setItem(tableName, tableName)
        
          await this.$router.push({ name: 'home', params: { id: tableName }})
        } catch(error) {
          console.log(`Unexpected error for removeTodoHandler: code = ${error.code}` + `, message = "${error.message}"`)
        }
      }

      // else {
        try {
          this.todos = await api.deleteTodo(this.activeTable, id)
          // JB: re-order any subsequent entries once deleted
          
        } catch(error) {
          console.log(`Unexpected error for removeTodoHandler: code = ${error.code}` + `, message = "${error.message}"`)
        }
      // }
    },

    cancelTodoHandler(item) {
      console.log('\ncancelTodoHandler() >> ', item)

      // If we are in multi-select edit mode then we don't use the cancel entry feature
      if(this.isMultiEdit) return false

      // const index = this.editTodo.indexOf(item);
      // this.editTodo.splice(index, 1)
      // DEVNOTE: workaround for this craveat when watching non-primitives; https://v2.vuejs.org/v2/api/?redirect=true#vm-watch
      this.editTodo = this.editTodo.filter((todo) => todo !== item)
    },

    /*
    * Multi-select edit mode
    **/
    selectAllHandler() {
      // console.log('\nselectAllHandler() >> ')

      // JB : can probably do with reduce
      // Recirculate the edit todo collection(what we have selected) against the filtered collection(how the UI view has been filtered by); this allows us to select only what corresponds with the UI at the time the action was made. all, complete or incomplete todos and allows us to culmatively add the select all entries if the use was on a subset like complete and then repeated the select all operation on incomplete. rather than wipe the slate clean and replace with the subset it is additive.
      // if we push items within the forEach directly to this.editTodoCollection then because of the mutation the watcher will not reflect in the new and old values(it will show them as being identical). we use a temp array as a wrapper for the operation and then assign it directly to this.editTodoCollection in one hit to overcome this.
      // DEVNOTE: workaround for this craveat when watching non-primitives; https://v2.vuejs.org/v2/api/?redirect=true#vm-watch
      const temp = [...this.editTodoCollection]
      this.filteredTodos.forEach((item) => {
        if(!this.editTodoCollection.includes(item)) temp.push(item)
        // if(!this.editTodoCollection.includes(item)) this.editTodoCollection.push(item)
        // if(!this.editTodoCollection.includes(item)) this.$set(this.editTodoCollection, this.editTodoCollection.length, item)
      })

      this.editTodoCollection = temp
    },

    cancelSelectedHandler() {
      // console.log('\ncancelSelectedHandler() >> ')

      // remove selection from current active collection only; all, complete, incomplete
      this.filteredTodos.forEach((item) => {
        if(this.editTodoCollection.includes(item)) {
          // const index = this.editTodoCollection.indexOf(item)
          // this.editTodoCollection.splice(index, 1)
          // DEVNOTE: workaround for this craveat when watching non-primitives; https://v2.vuejs.org/v2/api/?redirect=true#vm-watch
          this.editTodoCollection = this.editTodoCollection.filter((todo) => todo !== item)
        }
      })
    },

    async deleteSelectedHandler() {
      // console.log('\ndeleteSelectedHandler() >> ', this.filteredEditTodoCollection)

      if(!this.isOwner) {
        console.log('Not your sandbox sunshine!')

        try {
          // const tableName = await api.createTable()
          // await api.createTodo(tableName, [...this.todos])
          // await api.deleteTodo(tableName, this.filteredEditTodoCollection.map(({ id }) => id))

          const tableName = await api.cloneTable(this.activeTable)

          localStorage.setItem(tableName, tableName)
        
          await this.$router.push({ name: 'home', params: { id: tableName }})
        } catch(error) {
          console.log(`Unexpected error for deleteSelectedHandler: code = ${error.code}` + `, message = "${error.message}"`)
        }
      }

      // else {
        try {
          this.todos = await api.deleteTodo(this.activeTable, this.filteredEditTodoCollection.map(({ id }) => id))
          // JB: re-order any subsequent entries once deleted
          
        } catch(error) {
          console.log(`Unexpected error for deleteSelectedHandler: code = ${error.code}` + `, message = "${error.message}"`)
        } finally {
          this.editTodoCollection = [];
        }
      // }
    },

    async saveSelectedHandler() {
      // console.log('\nsaveSelectedHandler() >> ', this.filteredEditTodoCollection)
      
      if(!this.isOwner) {
        console.log('Not your sandbox sunshine!')

        try {
          // const tableName = await api.createTable()
          // await api.createTodo(tableName, [...this.todos])
          // await api.updateTodo(tableName, this.filteredEditTodoCollection)

          const tableName = await api.cloneTable(this.activeTable)

          localStorage.setItem(tableName, tableName)
        
          await this.$router.push({ name: 'home', params: { id: tableName }})
        } catch(error) {
          console.log(`Unexpected error for saveSelectedHandler: code = ${error.code}` + `, message = "${error.message}"`)
        }
      }

      // else {
        try {
          this.todos = await api.updateTodo(this.activeTable,  this.filteredEditTodoCollection)
          // JB: re-order any subsequent entries once deleted
          
        } catch(error) {
          console.log(`Unexpected error for saveSelectedHandler: code = ${error.code}` + `, message = "${error.message}"`)
        } finally {
          this.editTodoCollection = [];
        }
      // }
    },

    /*
    * Re-order todos
    **/
    dragstartTodoHandler(event, item) {
      // console.log('-- drag start --', event.target, ' :: ', event.srcElement, ' :: ', event.toElement)
      event.dataTransfer.effectAllowed = 'move';
      // event.dataTransfer.setData('$el', event.target)

      // this.beingDraggedElement = event.target
      this.beingDraggedTodo = item
      /* this.$set(item, 'isDragging', true) */
    },

    dragendTodoHandler(event, item, collection) {
      // console.log('-- drag end --')
      // this.beingDraggedElement = null
      this.beingDraggedTodo = null
      /* this.$set(item, 'isDragging', false) */

      /* this.lastEnteredElement = null */
      this.lastEnteredTodo = null
      /* collection.forEach( (item) => this.$set(item, 'isDragOver', false)) */ // JB: property won't be reactive as not initialised in data prop so csn dynamic add and make reactive with Vue.set()
    },

    dragenterTodoHandler(event, item) {      
      // make sure we are not dragging into the same item being dragged
      // if(!event.path.includes(this.beingDraggedElement)) {
      // if(item.id !== this.beingDraggedTodo.id) {
      if(item !== this.beingDraggedTodo) {
        // console.log('-- drag enter --', event.target, ' :: ', event.srcElement, ' :: ', event.toElement)
        // console.log(this.beingDraggedElement)

        /* this.lastEnteredElement = event.target */
        this.lastEnteredTodo = item
        /* this.$set(item, 'isDragOver', true) */
      }
      
    },

    dragleaveTodoHandler(event, item) {
      /*
      if((event.target === event.path[0]) && (event.target === this.lastEnteredElement)) {
        // console.log('\n-- drag leave --', event.target, ' :: ', event.srcElement, ' :: ', event.toElement)
        // console.log('last entered :: ', this.lastEnteredElement)
        // console.log(event.path)

        this.$set(item, 'isDragOver', false)
      }

      // console.log(event.dataTransfer.getData('$el'))
      */
    },

    async dropTodoHandler(event, item) {

      // if(!event.path.includes(this.beingDraggedElement)) {
      // if(item.id !== this.beingDraggedTodo.id) {
      if(item !== this.beingDraggedTodo) {
        const target = item
        const source = this.beingDraggedTodo

        console.log(`
        ${source.task}  @${source.position}
          -- dropped on --
        ${target.task} @${target.position}`)

        if(!this.isOwner) {
          console.log('Not your sandbox sunshine!')

          try {
            // const tableName = await api.createTable()
            // await api.createTodo(tableName, [...this.todos])
            // await api.updateTodo(tableName, [{...source, position: target.position}, {...target, position: source.position}])

            const tableName = await api.cloneTable(this.activeTable)

            localStorage.setItem(tableName, tableName)
          
            await this.$router.push({ name: 'home', params: { id: tableName }})
          } catch(error) {
            console.log(`Unexpected error for dropTodoHandler: code = ${error.code}` + `, message = "${error.message}"`)
          }
        }

        // else {
          try {
            // JB: handle multiple updates using a source array
            // JB: likely to break upon selection with reference comparison between this.editTodoCollection and this.todos; although call api.getTodo() and not rely on returned set off api.updateTodo so might not
            // await api.updateSingleTodo({...source, position: target.position})
            // await api.updateSingleTodo({...target, position: source.position})
            this.todos = await api.updateTodo(this.activeTable, [{...source, position: target.position}, {...target, position: source.position}])
            // this.todos = await api.getTodo(this.activeTable) // JB : replace with response from api.updateTodo()
          } catch(error) {
            console.log(`Unexpected error for dropTodoHandler: code = ${error.code}` + `, message = "${error.message}"`)
          }
        // }
      }
    },

    async tableChangeHandler(event) {
      console.log('tableChangeHandler() ', event.target.value, ' :: ', this.activeTable, ' :: ', /^[0-9A-Za-z]{10}$/.test(event.target.value))
      
      // None - go to home '/'
      if(!/^[0-9A-Za-z]{10}$/.test(event.target.value)) {
        this.todos = []
        await this.$router.push({ name: 'home', params: { id: event.target.value }})
      } else {

        try {
          this.todos = await api.getTable(event.target.value)
          await this.$router.push({ name: 'home', params: { id: event.target.value }})
        } catch(error) {
          console.log(`Unexpected error for getTableClickHandler: code = ${error.code}` + `, message = "${error.message}"`)
        }
      }
    },

    async saveTodoListHandler() {
      try {
        this.tableName = await api.createTable()

        // const expiry = Date.now() + 86400000 // TODO: DateTime util module
        localStorage.setItem(this.tableName, this.tableName)
          
        await this.$router.push({ name: 'home', params: { id: this.tableName }})
      } catch(error) {
        console.log(`Unexpected error for saveTodoListHandler: code = ${error.code}` + `, message = "${error.message}"`)
      }
    },

    async deleteTodoListkHandler() {
      try {
        this.todos = await api.deleteTable(this.activeTable)

        localStorage.removeItem(this.tableName)

        await this.$router.push({ name: 'home' })
      } catch(error) {
        console.log(`Unexpected error for deleteTodoListkHandler: code = ${error.code}` + `, message = "${error.message}"`)
      }
    },
  },

  computed: {
    filteredTodos() {
      // console.log('\nCOMPUTED filteredTodos() >> ')

      const { todos, isFilter } = this

      switch (isFilter) {
        case 1:
          return todos.slice().filter(({status}) => status === Status.COMPLETE).sort(this.sortByAscending)
        case 2:
          return todos.slice().filter(({status}) => status === Status.INCOMPLETE).sort(this.sortByAscending)
        default:
          return todos.slice().sort(this.sortByAscending)
      }
    },

    filteredEditTodoCollection() {
      // console.log('\nCOMPUTED filteredEditTodoCollection() >> ')

      return this.editTodoCollection.filter( (item, index) => {
        // console.log(index, ' :: ', this.filteredTodos.includes(item))
        return this.filteredTodos.includes(item)
      })
      // return this.filteredTodos.filter( (item) => this.editTodoCollection.includes(item))
    },

    completeCount() {
      const count = this.todos.filter(({status}) => status === Status.COMPLETE).length
      
      if(count === this.todos.length) {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.isFilter = 0
      }
      return count
    },

    incompleteCount() {
      const count = this.todos.filter(({status}) => status === Status.INCOMPLETE || status === Status.NULL).length
      
      if(count === this.todos.length) {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.isFilter = 0
      }
      return count
    },

    isSingleEdit() {
      return !!this.editTodo.length
    },

    isMultiEdit() {
      return !!this.filteredEditTodoCollection.length
    },

    isModified() {
      const singularTest = this.editTodo.every((item) => {
        const match = this.cachedTodos.find(({ id }) => id === item.id)
        console.log('match :: ', match)
        return item.task === match.task
      })

      const multiTest = this.filteredEditTodoCollection.every((item) => {
        const match = this.cachedTodos.find(({ id }) => id === item.id)
        console.log('match :: ', match)
        return item.task === match.task
      })

      // console.log('\nCOMPUTED isModified >> ', !singularTest, ' :: ', !multiTest)

      return !singularTest || !multiTest
    },

    filteredTableCollection() {
      return this.tableCollection.filter((name) => {
        return /^[0-9A-Za-z]{10}$/.test(name)
      })
    },

    activeTable: {
      get() {
        return this.$route.params.id || null
      },

      set(value) {
        this.tableName = value
      }
    },

    isOwner() {
      console.log('isOwner() :token: ', this.token, ' :activeTable: ', this.activeTable)
      // return this.token === this.activeTable // JB: this conditional check not necessary as the key is the route.params.id/tablename so not found is equivalent to not the owner
      return !!this.token || !this.$route.params.id
      // return false // JB: Dev only force no ownership for debugging
    },
  },

  watch: {
    // ['$route.params']: 'fetchTodos',
    ['$route.params']: async function (newValue, oldValue) {
      console.log('** ROUTE ** => watch FIRED!', newValue, oldValue)

      this.todos = newValue.id ? await api.getTodo(newValue.id) : []

      // TODO: ownership ie localstorage
      this.token = localStorage.getItem(this.$route.params.id)

      // JB: this is just dev only boilerplate; can live here for now
      this.tableCollection = await api.getTableNames()
    },

    editTodo(newValue, oldValue) {
      // console.log('\nWATCH editTodo() >> ', oldValue, ' :: ', newValue)

      if(newValue.length > oldValue.length) {
        const selectedCollection = [...newValue]

        // console.log(' :selected: ', selectedCollection[0].id)

        this.cachedTodos = [...this.cachedTodos, ...selectedCollection].reduce((accum, curr) => {
          const index = accum.findIndex(({id}) => id === curr.id)
          
          if(index > -1) {
            accum[index] = { ...curr };
          } else {
            accum.push({ ...curr });
          }
          
          return accum;
        }, [])

      } else if(newValue.length <= oldValue.length) {
        const deselectedCollection = oldValue.filter((item) => !newValue.includes(item))

        // console.log(' :deselected collection: ', deselectedCollection)

        // if any todos have changed find them and reset from cache
        deselectedCollection.forEach((item) => {
          const cachedItem = this.cachedTodos.find(({ id }) => id === item.id)

          // JB: could intercept and test for change before making assignment however for the timebeing this is not necessary and won't break anything
          item.task = cachedItem.task
        })
      }
    },

    // ['editTodo.length'](newvalue, oldvalue) {
    //     console.log('edittodo length ', oldvalue, newvalue)
    // },

    editTodoCollection(newValue, oldValue) {
      // console.log('\nWATCH editTodoCollection() >> ', oldValue, ' :: ', newValue)
      
      if(newValue.length > oldValue.length) {
        // JB: map and intersection?
        const selectedCollection = [...newValue]

        // console.log(' :selected: ', selectedCollection[0].id)

        // Create a cached reference of our todo collection; we will use this for comparison later and to determine if any changes have been made
        // DEVNOTE: we don't want the cached objects to be reactive so we clone striping it free of the orginal referenced observer(if we don't do this it would be simply be an assignment by reference to the collection of reactive objects and tarnish our attempt to cache state)
        // JB: filter, reduce, map or set
        this.cachedTodos = [...this.cachedTodos, ...selectedCollection].reduce((accum, curr) => {
          const index = accum.findIndex(({id}) => id === curr.id)
          
          if(index > -1) {
            accum[index] = { ...curr };
          } else {
            accum.push({ ...curr });
          }
          
          return accum;
        }, [])

      } else if(newValue.length <= oldValue.length) {
        const deselectedCollection = oldValue.filter((item) => !newValue.includes(item))

        // console.log(' :deselected collection: ', deselectedCollection)

        // if any todos have changed find them and reset from cache
        deselectedCollection.forEach((item) => {
          const cachedItem = this.cachedTodos.find(({ id }) => id === item.id)

          // JB: could intercept and test for change before making assignment however for the timebeing this is not necessary and won't break anything
          item.task = cachedItem.task
        })
      }
    }
  }
}
</script>

<style lang="scss" scoped>
@mixin for-mobile-only {
  @media (max-width: 599px) { @content; }
}
@mixin for-portrait-up {
  @media (min-width: 600px) { @content; }
}
@mixin for-landscape-up {
  @media (min-width: 900px) { @content; }
}
@mixin for-desktop-up {
  @media (min-width: 1200px) { @content; }
}
@mixin for-widescreen-up {
  @media (min-width: 1800px) { @content; }
}

$toolbar_gutter: 6px;

// .ui__todo-list.dev {
//   grid-template-areas: 
//     "dev dev2 capture"
//     "dev dev2 toolbar"
//     "dev dev2 body"
//     "dev dev2 auxiliary"
//     "dev dev2 actions"
//     "dev dev2 .";

//   @include for-desktop-up {
//     grid-template-columns: min(400px, 100%) min(400px, 100%) 1fr;
//     grid-template-columns: max-content 1fr;
//     grid-template-columns: minmax(min-content, max-content) 1fr;
//     grid-template-rows: auto;
//     grid-template-areas: 
//       "dev dev2 capture"
//       "dev dev2 toolbar"
//       "dev dev2 body"
//       "dev dev2 auxiliary"
//       "dev dev2 actions"
//       "dev dev2 .";
//   }
// }

// .ui__dev {
//   grid-area: dev;
//   max-height: 80%;
//   overflow: hidden auto;
// }

// .ui__dev2 {
//   grid-area: dev2;
//   overflow: hidden auto;
// }

.ui__todo-list {
  display: grid;
  grid-gap: 0 18px;
  grid-template-areas: 
    "capture"
    "toolbar"
    "body"
    "auxiliary"
    "actions";
  
  @include for-desktop-up {
    grid-template-columns: 1fr min(540px, 100%) 1fr;
    grid-template-areas: 
    ". capture ."
    ". toolbar ."
    ". body ."
    ". auxiliary ."
    ". actions .";
  }
}

.todo-list__capture {
  grid-area: capture;
  font-size: 14px;

  @include for-portrait-up {
    font-size: 16px;
  }

  &:before {
    content: 'Comma delimit to enter multiple entries';
    font-size: 12px;
    font-style: italic;
    color: $color__silver;
    display: block;
    margin: 1rem 0;

    @include for-portrait-up {
      font-size: 14px;
    }
  }

  input[type="text"] {
    // border-right-width: 0;
    padding: 9px 12px;
    flex: 1;

    @include focus($swatch__primary);
  }

  button {
    width: 41px;
    padding: 0 9px;

    @include focus($swatch__primary);

    @include for-portrait-up {
      width: auto;
    }

    &:focus {
      border-color: $swatch__primary;
    }
  }

  svg {
    @include for-portrait-up {
      margin-left: 9px;
    }
  }
}

.todo-list__toolbar {
  grid-area: toolbar;
  font-size: 12px;

  @include for-portrait-up {
    font-size: 14px;
    display: flex;
    align-items: center;
    gap: 6px;
  }

  svg {
    margin-right: 6px;
  }

  ul {
    margin: 1rem -6px;;
  }

  li {
    display: inline-block;
    padding: 0 6px;

    &:not(:first-child) {
      border-left: 1px solid #000;
    }

    svg {
      margin-left: 6px;
      margin-right: 0;
    }
  }
}

.todo-list__auxiliary {
  grid-area: auxiliary;
  font-size: 12px;
  display: flex;
  align-items: center;
  justify-content: space-between;

  @include for-portrait-up {
    font-size: 16px;
  }

  &--single-edit {
    opacity: 0.25;
  }

  p {
    margin: 0.5em 0;
  }
}

.todo-list__actions {
  grid-area: actions;
  font-size: 12px;

  @include for-portrait-up {
    font-size: 16px;
  }

  .form__control {
    align-items: center;
    justify-content: flex-end;

    &:before {
      content: '';
      height: 2px;
      background-color: $color__black;
      width: 100%;
      position: absolute;
      top: 0;
    }
  }

  .control__radio {
    flex: 0 1 auto;
    cursor: pointer;

    input {
      position: absolute;
      z-index: -1;
      visibility: hidden;
    }

    span {
      background-color: $color__black;
      color: $color__white;
      text-align: center;
      display: block;
      padding: 6px 18px;
    }

    &:hover,
    &:focus {
      span {
        background-color: $swatch__primary;
      }

      input:disabled ~ span {
        background-color: $color__black;
      }
    }

    input:checked ~ span {
      background: $swatch__secondary;
    }

    input:disabled ~ span {
      color: $color__silver;
      font-style: italic;
      opacity: 0.25;
      cursor: not-allowed;
    }
  }
}

.todo-list__body {
  grid-area: body;
  font-size: 14px;

  @include for-portrait-up {
    font-size: 16px;
  }
}

.todo-list__listing {
  margin-bottom: 2em;

  &:before {
    content: 'Drag and drop to reorder; double-click text to edit';
    font-size: 12px;
    font-style: italic;
    color: $color__silver;
    text-align: center;
    display: block;
    margin: 0 0 1rem 0;

    @include for-portrait-up {
      font-size: 14px;
    }
  }

  &--single-edit {
    .ui__todo {
      opacity: 0.25;
    }

    .ui__todo--single-edit {
      opacity: 1;
    }
  }

  &-item {
    background-color: $color__white;

    &--dragging {
      // background-color: $color__white;
      opacity: 0.8;

      .todo__toolbar {
        visibility: hidden;
      }
    }

    &--over {
      background-color: $swatch__primary;
    }
  }
}

.todo-list__list-item {
  display: flex;
  gap: 20px;
}

.ui__todo {
  display: flex;
  gap: 12px;
  align-items: center;
  margin: 12px 0;
  flex: 1 0 0%;

  &:hover {
    p {
      color: $swatch__primary;

      .todo-list__listing--single-edit &,
      .todo-list__listing--multi-edit & {
        color: inherit;
      }
    }

    .todo__toolbar,
    .todo__actions {
      display: flex;
    }

    .control__checkbox .control__checkbox-handle {
      border-color: $swatch__primary;
    }

    .control__checkbox :checked ~ .control__checkbox-handle {
      background-color: $swatch__primary;
    }
  }

  &--complete .task__body {
    text-decoration: line-through;
  }

  .control {
    // margin: 0 $toolbar_gutter;
    margin: 0;
  }

  input[type="text"] {
    flex: 1;
    border-width: 0;
    border-bottom-width: 2px;
    min-height: 30px;

    &:checked ~ p {
      text-decoration: line-through;
    }

    &:focus {
      outline: 0 none;
    }
  }  
}

.task__body {
  cursor: text;
  margin: 0;
  flex: 1 1 100%;

  .todo-list__listing--single-edit &,
  .todo-list__listing--multi-edit & {
    cursor: default;
  }
}

.task__field {
  border-bottom: 2px solid $color__black;

  display: flex;
  margin: 0 0 -2px;
  flex: 1 1 100%;

  input {
    background: none transparent;
    color: inherit;
    display: inline-block;
    // width: 100%;
    // margin: 1rem 0;
    min-height: initial;

    border: 0 none;
  }
}

.label__task {
  display: none;

  @include for-portrait-up {
    display: inline;
  }
}

.todo__toolbar {
  display: none;
  align-items: center;
  // margin: {
  //   left: -$toolbar_gutter;
  //   right: -$toolbar_gutter;
  // }

  button {
    background: transparent;
    color: $color__black;
    border: 0 none;
    display: block;
    height: 34px;

    &:hover {
      color: $swatch__primary;
    }
  }
}

.todo__actions {
  display: none;
  align-items: center;

  &--select {
    display: flex;
  }
}

.todo__task {
  display: flex;
  flex: 1 1 auto;
  align-items: center;
  // overflow: hidden;

  &:before {
    content: attr(position);
    background-color: $color__black;
    border-radius: 50%;
    font-size: 12px;
    color: $color__white;
    font-weight: 600;
    display: inline-flex;
    flex: 0 1 auto;
    align-items: center;
    justify-content: center;
    width: 2em;
    height: 2em;
    margin-right: 12px;
  }
}

/*
* FX - Vue named transitions
**/
.todo-list-move {
  transition: transform 400ms ease;
}
</style>
