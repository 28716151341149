import './scss/main.scss'
// import './css/tailwind.css'

import Vue from 'vue'
import router from './vue/router'
import { library } from '@fortawesome/fontawesome-svg-core'
import {
  faCheck,
  faEdit,
  faEraser,
  faPlus,
  faSave,
  faTimesCircle,
  faTrashAlt,
  faSync,
} from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import App from './vue/App.vue'

import './vue/directives/focus'
import './vue/directives/clickOutside'

library.add(faCheck, faEdit, faEraser, faPlus, faSave, faTrashAlt, faTimesCircle, faSync)
Vue.component('Icon', FontAwesomeIcon)

Vue.config.productionTip = false

new Vue({
  router,
  render: h => h(App),
}).$mount('#app')
