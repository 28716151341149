import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'

Vue.use(VueRouter)

const routes = [
  {
    name: 'home',
    // path: '/todos-:id(.*)?',
    // path: '/:id(todos-\\d+)*', // works
    // path: '/:id(todos-\\d+)*', // works
    // path: '/:id(todos-\\d+)?', // works
    path: '/:id([0-9A-Za-z]{10})*',
    component: Home,
  },
  {
    name: 'helloWorld',
    path: '/hello-world',
    component: () => import(/* webpackChunkName: 'hello-world' */ '../views/HelloWorld.vue'),
  },
  {
    name: 'productInventory',
    path: '/product-inventory',
    component: () => import(/* webpackChunkName: 'product-inventory' */ '../views/ProductInventory.vue'),
  },
  {
    name: 'routeGuide',
    path: '/route-guide',
    component: () => import(/* webpackChunkName: 'route-guide' */ '../views/RouteGuide.vue'),
  },
  {
    name: 'echoApp',
    path: '/echo-app',
    component: () => import(/* webpackChunkName: 'echo-app' */ '../views/EchoApp.vue'),
  },
  {
    name: 'about',
    path: '/about',
    component: () => import(/* webpackChunkName: 'about' */ '../views/About.vue'),
  },
  {
    name: 'notFound',
    // path: '*',
    path: '/:catchAll(.*)*', // JB: The vue router 4 way
    component: () => import(/* webpackChunkName: 'not-found' */  '../views/NotFound.vue'),
  },
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
})

export default router
