<template lang="pug">
  .view__home
    section
      //- h1 Taskmaster
      //- p Possibly&hellip;the most over-engineered todo list demo ever
      //- p Guest sandboxes are enabled. User enrolment is not. Bookmark url if you wish to return. Only owners can edit their sandbox. All sandboxes expire after 2 weeks of inactivity. All empty sandboxes are purged after 24 hours.
      //- p The only condition of using this application is that you do not post anything offensive or obscene. But please be aware, this is a publically accessible domain and although I appeal to you to behave I can not control user authored content and take no responsiblilty for the material presented. Nevertheless urls are obfuscated for your protection. Please exercise caution and do not post anything private or sensitive. Thank you.
      //- p No personal data is harvested or stored for the purposes of this application. All data is anonymously held and automatically scheduled for deletion. Any client storage mechanisms are strictly in place for functionality or performance. Analytics may be be in use.
      TodoList(:hasTable="hasTable")
      //- TodoList(:hasTable="hasTable" :key="$route.params.id")
      //- TodoListTest

      //- Component(:is="view")
</template>

<script>
import TodoService from '@/js/TodoService'

import TodoList from '@/vue/components/TodoList.vue'
// import TodoListTest from '@/vue/components/TodoListTest.vue'

const PROTOCOL = 'http'
const PORT = 8080
const API_URI = `${PROTOCOL}://${window.location.hostname}:${PORT}`

const api = new TodoService(API_URI)

export default {
  name: 'Home',

  components: {
    TodoList,
    // TodoListTest,
  },

  data() {
    return {
      hasTable: false,
    }
  },

  // Called before the route that renders this component is confirmed. Component doesn't exist yet.
  async beforeRouteEnter(to, from, next) {
    console.log('** ROUTE ** => beforeRouteEnter hook')

    // JB: PRODUCTION version
    // NOTE: seeing as I've made the call and to defer doing it again in component created() - like i do - i can pass that to the vm and then pass as a prop. mind i hate prop mindless prop usage
    const hasTable = (to.params.id) ? await api.hasTable(to.params.id) : false

    if(!hasTable && to.params.id) {
      console.log('NOT FOUND :hasTable: ', hasTable, ' :table name: ', to.params.id)

      // next({ name: 'notFound', params: [to.path], query: to.query, hash: to.hash })

      next({
        name: 'notFound',
        params: { catchAll: to.path.split('/').slice(1) },
        query: to.query,
        hash: to.hash,
      })
    } else {
      console.log('TABLE FOUND OR ROOT :hasTable: ', hasTable, ' :table name: ', to.params.id)

      next()
    }

    next(vm => vm.hasTable = hasTable)
    
    /*
    // JB: access to component instance via `vm` passed to callback
    next(async vm => {
      if(to.params.id) vm.hasTable = await api.hasTable(to.params.id) // JB: technically im only doing this in the next() callback because i want visually confirmation and to bind it to state so need access to the vm; in practise it can be done outside of next

      if(!vm.hasTable && to.params.id) {
        console.log('NOT FOUND')

        // JB: these are redirects - the current navigation will be aborted and a new one started so they hit the existing config
        // next(`/${to.params.id}/not-found`)
        // next({ path: `/${to.params.id}/not-found`, replace: true })
        // next({ name: 'notFound' })
        // next({ name: 'notFound', params: [to.path] }) // this works by witchcraft! // https://github.com/vuejs/vue-router/issues/977#issuecomment-304498068
        // next({ name: 'notFound', params: to.params, query: to.query, hash: to.hash })
        // next({ name: 'notFound', params: [to.path], query: to.query, hash: to.hash, replace: true })
        // next({ name: 'notFound', params: [to.path], query: to.query, hash: to.hash })

        // JB: The vue router 4 way
        next({
          name: 'notFound',
          // Match the path of your current page and keep the same url...
          params: { catchAll: to.path.split('/').slice(1) },
          query: to.query,
          hash: to.hash,
        })

      } else {
        console.log('TABLE FOUND OR ROOT')
        
        next()
      }
    })
    */
  },

  // Called when route changes but component instance is re-used(already mounted)
  async beforeRouteUpdate(to, from, next) {
    console.log('** ROUTE ** => beforeRouteUpdate hook')
    
    this.hasTable = (to.params.id) ? await api.hasTable(to.params.id) : false
    
    if(!this.hasTable && to.params.id) {
      console.log('NOT FOUND :hasTable: ', this.hasTable, ' :table name: ', to.params.id)

      // next({ name: 'notFound', params: [to.path], query: to.query, hash: to.hash })

      next({
        name: 'notFound',
        params: { catchAll: to.path.split('/').slice(1) },
        query: to.query,
        hash: to.hash,
      })
    } else {
      console.log('TABLE FOUND OR ROOT :hasTable: ', this.hasTable, ' :table name: ', to.params.id)

      next()
    }
  },

  computed: {
    // JB: taking this one step further. you could probably create a vue instance in the vue router that is initialised with vuex store.
    // then use a computed off that instance to return a conditional component to display on route index.js config that is tied to vuex state. similar to the event bus ethos
    view() {
      return (!this.hasTable && this.$route.params.id) ? () => import(/* webpackChunkName: 'not-found' */  '../views/NotFound.vue') : TodoList
    }
  }
}
</script>
