/**
 * @fileoverview gRPC-Web generated client stub for todo
 * @enhanceable
 * @public
 */

// GENERATED CODE -- DO NOT EDIT!


/* eslint-disable */
// @ts-nocheck



const grpc = {};
grpc.web = require('grpc-web');

const proto = {};
proto.todo = require('./todo_pb.js');

/**
 * @param {string} hostname
 * @param {?Object} credentials
 * @param {?grpc.web.ClientOptions} options
 * @constructor
 * @struct
 * @final
 */
proto.todo.TodoServiceClient =
    function(hostname, credentials, options) {
  if (!options) options = {};
  options.format = 'text';

  /**
   * @private @const {!grpc.web.GrpcWebClientBase} The client
   */
  this.client_ = new grpc.web.GrpcWebClientBase(options);

  /**
   * @private @const {string} The hostname
   */
  this.hostname_ = hostname;

};


/**
 * @param {string} hostname
 * @param {?Object} credentials
 * @param {?grpc.web.ClientOptions} options
 * @constructor
 * @struct
 * @final
 */
proto.todo.TodoServicePromiseClient =
    function(hostname, credentials, options) {
  if (!options) options = {};
  options.format = 'text';

  /**
   * @private @const {!grpc.web.GrpcWebClientBase} The client
   */
  this.client_ = new grpc.web.GrpcWebClientBase(options);

  /**
   * @private @const {string} The hostname
   */
  this.hostname_ = hostname;

};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.todo.TableName,
 *   !proto.todo.TableStatus>}
 */
const methodDescriptor_TodoService_hasTable = new grpc.web.MethodDescriptor(
  '/todo.TodoService/hasTable',
  grpc.web.MethodType.UNARY,
  proto.todo.TableName,
  proto.todo.TableStatus,
  /**
   * @param {!proto.todo.TableName} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.todo.TableStatus.deserializeBinary
);


/**
 * @param {!proto.todo.TableName} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.todo.TableStatus)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.todo.TableStatus>|undefined}
 *     The XHR Node Readable Stream
 */
proto.todo.TodoServiceClient.prototype.hasTable =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/todo.TodoService/hasTable',
      request,
      metadata || {},
      methodDescriptor_TodoService_hasTable,
      callback);
};


/**
 * @param {!proto.todo.TableName} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.todo.TableStatus>}
 *     Promise that resolves to the response
 */
proto.todo.TodoServicePromiseClient.prototype.hasTable =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/todo.TodoService/hasTable',
      request,
      metadata || {},
      methodDescriptor_TodoService_hasTable);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.todo.Empty,
 *   !proto.todo.TableList>}
 */
const methodDescriptor_TodoService_getTableNames = new grpc.web.MethodDescriptor(
  '/todo.TodoService/getTableNames',
  grpc.web.MethodType.UNARY,
  proto.todo.Empty,
  proto.todo.TableList,
  /**
   * @param {!proto.todo.Empty} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.todo.TableList.deserializeBinary
);


/**
 * @param {!proto.todo.Empty} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.todo.TableList)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.todo.TableList>|undefined}
 *     The XHR Node Readable Stream
 */
proto.todo.TodoServiceClient.prototype.getTableNames =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/todo.TodoService/getTableNames',
      request,
      metadata || {},
      methodDescriptor_TodoService_getTableNames,
      callback);
};


/**
 * @param {!proto.todo.Empty} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.todo.TableList>}
 *     Promise that resolves to the response
 */
proto.todo.TodoServicePromiseClient.prototype.getTableNames =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/todo.TodoService/getTableNames',
      request,
      metadata || {},
      methodDescriptor_TodoService_getTableNames);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.todo.TableName,
 *   !proto.todo.TodoList>}
 */
const methodDescriptor_TodoService_getTable = new grpc.web.MethodDescriptor(
  '/todo.TodoService/getTable',
  grpc.web.MethodType.UNARY,
  proto.todo.TableName,
  proto.todo.TodoList,
  /**
   * @param {!proto.todo.TableName} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.todo.TodoList.deserializeBinary
);


/**
 * @param {!proto.todo.TableName} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.todo.TodoList)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.todo.TodoList>|undefined}
 *     The XHR Node Readable Stream
 */
proto.todo.TodoServiceClient.prototype.getTable =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/todo.TodoService/getTable',
      request,
      metadata || {},
      methodDescriptor_TodoService_getTable,
      callback);
};


/**
 * @param {!proto.todo.TableName} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.todo.TodoList>}
 *     Promise that resolves to the response
 */
proto.todo.TodoServicePromiseClient.prototype.getTable =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/todo.TodoService/getTable',
      request,
      metadata || {},
      methodDescriptor_TodoService_getTable);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.todo.TableName,
 *   !proto.todo.TableName>}
 */
const methodDescriptor_TodoService_createTable = new grpc.web.MethodDescriptor(
  '/todo.TodoService/createTable',
  grpc.web.MethodType.UNARY,
  proto.todo.TableName,
  proto.todo.TableName,
  /**
   * @param {!proto.todo.TableName} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.todo.TableName.deserializeBinary
);


/**
 * @param {!proto.todo.TableName} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.todo.TableName)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.todo.TableName>|undefined}
 *     The XHR Node Readable Stream
 */
proto.todo.TodoServiceClient.prototype.createTable =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/todo.TodoService/createTable',
      request,
      metadata || {},
      methodDescriptor_TodoService_createTable,
      callback);
};


/**
 * @param {!proto.todo.TableName} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.todo.TableName>}
 *     Promise that resolves to the response
 */
proto.todo.TodoServicePromiseClient.prototype.createTable =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/todo.TodoService/createTable',
      request,
      metadata || {},
      methodDescriptor_TodoService_createTable);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.todo.TableName,
 *   !proto.todo.TableName>}
 */
const methodDescriptor_TodoService_cloneTable = new grpc.web.MethodDescriptor(
  '/todo.TodoService/cloneTable',
  grpc.web.MethodType.UNARY,
  proto.todo.TableName,
  proto.todo.TableName,
  /**
   * @param {!proto.todo.TableName} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.todo.TableName.deserializeBinary
);


/**
 * @param {!proto.todo.TableName} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.todo.TableName)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.todo.TableName>|undefined}
 *     The XHR Node Readable Stream
 */
proto.todo.TodoServiceClient.prototype.cloneTable =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/todo.TodoService/cloneTable',
      request,
      metadata || {},
      methodDescriptor_TodoService_cloneTable,
      callback);
};


/**
 * @param {!proto.todo.TableName} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.todo.TableName>}
 *     Promise that resolves to the response
 */
proto.todo.TodoServicePromiseClient.prototype.cloneTable =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/todo.TodoService/cloneTable',
      request,
      metadata || {},
      methodDescriptor_TodoService_cloneTable);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.todo.TableName,
 *   !proto.todo.TodoList>}
 */
const methodDescriptor_TodoService_deleteTable = new grpc.web.MethodDescriptor(
  '/todo.TodoService/deleteTable',
  grpc.web.MethodType.UNARY,
  proto.todo.TableName,
  proto.todo.TodoList,
  /**
   * @param {!proto.todo.TableName} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.todo.TodoList.deserializeBinary
);


/**
 * @param {!proto.todo.TableName} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.todo.TodoList)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.todo.TodoList>|undefined}
 *     The XHR Node Readable Stream
 */
proto.todo.TodoServiceClient.prototype.deleteTable =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/todo.TodoService/deleteTable',
      request,
      metadata || {},
      methodDescriptor_TodoService_deleteTable,
      callback);
};


/**
 * @param {!proto.todo.TableName} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.todo.TodoList>}
 *     Promise that resolves to the response
 */
proto.todo.TodoServicePromiseClient.prototype.deleteTable =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/todo.TodoService/deleteTable',
      request,
      metadata || {},
      methodDescriptor_TodoService_deleteTable);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.todo.TodoIdCollection,
 *   !proto.todo.TodoList>}
 */
const methodDescriptor_TodoService_getTodo = new grpc.web.MethodDescriptor(
  '/todo.TodoService/getTodo',
  grpc.web.MethodType.UNARY,
  proto.todo.TodoIdCollection,
  proto.todo.TodoList,
  /**
   * @param {!proto.todo.TodoIdCollection} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.todo.TodoList.deserializeBinary
);


/**
 * @param {!proto.todo.TodoIdCollection} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.todo.TodoList)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.todo.TodoList>|undefined}
 *     The XHR Node Readable Stream
 */
proto.todo.TodoServiceClient.prototype.getTodo =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/todo.TodoService/getTodo',
      request,
      metadata || {},
      methodDescriptor_TodoService_getTodo,
      callback);
};


/**
 * @param {!proto.todo.TodoIdCollection} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.todo.TodoList>}
 *     Promise that resolves to the response
 */
proto.todo.TodoServicePromiseClient.prototype.getTodo =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/todo.TodoService/getTodo',
      request,
      metadata || {},
      methodDescriptor_TodoService_getTodo);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.todo.NewTodoCollection,
 *   !proto.todo.result>}
 */
const methodDescriptor_TodoService_createTodo = new grpc.web.MethodDescriptor(
  '/todo.TodoService/createTodo',
  grpc.web.MethodType.UNARY,
  proto.todo.NewTodoCollection,
  proto.todo.result,
  /**
   * @param {!proto.todo.NewTodoCollection} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.todo.result.deserializeBinary
);


/**
 * @param {!proto.todo.NewTodoCollection} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.todo.result)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.todo.result>|undefined}
 *     The XHR Node Readable Stream
 */
proto.todo.TodoServiceClient.prototype.createTodo =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/todo.TodoService/createTodo',
      request,
      metadata || {},
      methodDescriptor_TodoService_createTodo,
      callback);
};


/**
 * @param {!proto.todo.NewTodoCollection} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.todo.result>}
 *     Promise that resolves to the response
 */
proto.todo.TodoServicePromiseClient.prototype.createTodo =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/todo.TodoService/createTodo',
      request,
      metadata || {},
      methodDescriptor_TodoService_createTodo);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.todo.TodoCollection,
 *   !proto.todo.TodoList>}
 */
const methodDescriptor_TodoService_updateTodo = new grpc.web.MethodDescriptor(
  '/todo.TodoService/updateTodo',
  grpc.web.MethodType.UNARY,
  proto.todo.TodoCollection,
  proto.todo.TodoList,
  /**
   * @param {!proto.todo.TodoCollection} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.todo.TodoList.deserializeBinary
);


/**
 * @param {!proto.todo.TodoCollection} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.todo.TodoList)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.todo.TodoList>|undefined}
 *     The XHR Node Readable Stream
 */
proto.todo.TodoServiceClient.prototype.updateTodo =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/todo.TodoService/updateTodo',
      request,
      metadata || {},
      methodDescriptor_TodoService_updateTodo,
      callback);
};


/**
 * @param {!proto.todo.TodoCollection} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.todo.TodoList>}
 *     Promise that resolves to the response
 */
proto.todo.TodoServicePromiseClient.prototype.updateTodo =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/todo.TodoService/updateTodo',
      request,
      metadata || {},
      methodDescriptor_TodoService_updateTodo);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.todo.TodoIdCollection,
 *   !proto.todo.TodoList>}
 */
const methodDescriptor_TodoService_deleteTodo = new grpc.web.MethodDescriptor(
  '/todo.TodoService/deleteTodo',
  grpc.web.MethodType.UNARY,
  proto.todo.TodoIdCollection,
  proto.todo.TodoList,
  /**
   * @param {!proto.todo.TodoIdCollection} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.todo.TodoList.deserializeBinary
);


/**
 * @param {!proto.todo.TodoIdCollection} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.todo.TodoList)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.todo.TodoList>|undefined}
 *     The XHR Node Readable Stream
 */
proto.todo.TodoServiceClient.prototype.deleteTodo =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/todo.TodoService/deleteTodo',
      request,
      metadata || {},
      methodDescriptor_TodoService_deleteTodo,
      callback);
};


/**
 * @param {!proto.todo.TodoIdCollection} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.todo.TodoList>}
 *     Promise that resolves to the response
 */
proto.todo.TodoServicePromiseClient.prototype.deleteTodo =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/todo.TodoService/deleteTodo',
      request,
      metadata || {},
      methodDescriptor_TodoService_deleteTodo);
};


module.exports = proto.todo;

